export class UserModel {
  constructor(id: string, firstName: string, lastName: string, email: string) {
    this.userId = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
  }
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
}
