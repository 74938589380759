import {
  LoginAction,
  LoginActionTypes,
} from './login.actions';
import { createSelector, ActionReducerMap } from '@ngrx/store';
import { UserModel } from '../../model/auth-user';

export interface Login {
  signInPending?: boolean;
  signInError?: string;
  checkingSession?: boolean;
  CheckSessionSuccess?: boolean;
  CheckSessionFailure?: boolean;
  sessionError?: string;
  loggedIn?: boolean;
  user?: UserModel;
}

export interface LoginState {
  readonly login: Login;
}

export const loginInitialState: Login = {
};

export const loginReducers: ActionReducerMap<LoginState> = {
  login: loginReducer
};

export const initLoginState: LoginState = {
  login: loginInitialState
};

export function loginReducer(state: Login, action: LoginAction): Login {
  switch (action.type) {
    // SIGN IN
    case LoginActionTypes.SignIn: {
      return { ...state, ...{ signInPending: true } };
    }
    case LoginActionTypes.SignInSuccess: {
      return { ...state, ...{ signInPending: false, loggedIn: true } };
    }
    case LoginActionTypes.SignInFailure: {
      return {
        ...state,
        ...{ signInPending: false, signInError: action.payload }
      };
    }
    case LoginActionTypes.CheckSession: {
      return { ...state, checkingSession: true };
    }

    case LoginActionTypes.CheckSessionSuccess: {
      return { ...state, checkingSession: false, loggedIn: action.payload === 'true'};
    }

    case LoginActionTypes.CheckSessionFailure: {
      return {
        ...state,
        checkingSession: false,
        loggedIn: false,
        sessionError: action.payload
      };
    }
    case LoginActionTypes.LogOut: {
      return { ...state, loggedIn: false };
    }
    case LoginActionTypes.SetUser: {
      return { ...state, user: action.payload, loggedIn: true };
    }
    default: {
      return state;
    }
  }
}

export const selectLoginState = (s: LoginState) => s.login;

export const selectLoggedIn = createSelector(
  selectLoginState,
  s => s.loggedIn
);

export const selectSignInPending = createSelector(
  selectLoginState,
  s => s.signInPending
);
export const selectSignInError = createSelector(
  selectLoginState,
  s => s.signInError
);
