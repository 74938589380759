import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Router } from '@angular/router';
import { Authenticate } from '../model/authenticate';
import { UserModel } from '../model/auth-user';
import { ApiService } from '../api/api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private router: Router, private api: ApiService) { }

  loggedIn = false;

  login(loginData: Authenticate) {
    const content = {
      email: loginData.email,
      password: loginData.password
    };
    return this.api.post('/user/login', content).pipe(
      map(
        response => {
          sessionStorage.setItem('UserId', response.payload.userId);
          sessionStorage.setItem('UserRole', response.payload.role);
          return new UserModel(
            response.payload.userId,
            response.payload.firstName,
            response.payload.lastName,
            response.payload.email
          );
        }, error => error.error.msg)
    );
  }

  checkSession() {
    // er ni bol checksession deer serverluugee handaj session baigaa esehee shalgana
    const result = new ReplaySubject<string>();
    result.next(localStorage.getItem('loggedIn'));
    return result.asObservable();
  }

  logout() {
    const result = new ReplaySubject<boolean>();
    this.loggedIn = false;
    result.next(this.loggedIn);
    return result;
  }
}
