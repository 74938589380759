import { Action } from '@ngrx/store';
import { Authenticate } from '../../model/authenticate';
import { UserModel } from '../../model/auth-user';

export enum LoginActionTypes {
  SignIn = '[Login] Sign In',
  SignInSuccess = '[Login] SignIn Success',
  SignInFailure = '[Login] SignIn Failure',
  CheckSession = '[Login] Check session',
  CheckSessionSuccess = '[Login] Check session success',
  CheckSessionFailure = '[Login] Check session failure',
  SetUser = '[Login] Set User',
  LogOut = '[Login] Log Out',
  RedirectToMain = '[Login] Redirect to master',
  RedirectToLogin = '[Login] Redirect to login'
}

// SingIn actions
export class SignIn implements Action {
  readonly type = LoginActionTypes.SignIn;
  constructor(public payload: Authenticate) {}
}

export class SignInSuccess implements Action {
  readonly type = LoginActionTypes.SignInSuccess;
  constructor(public payload: UserModel) {}
}

export class SignInFailure implements Action {
  readonly type = LoginActionTypes.SignInFailure;
  constructor(public payload: string) {}
}

export class CheckSession implements Action {
  readonly type = LoginActionTypes.CheckSession;
}

export class CheckSessionSuccess implements Action {
  readonly type = LoginActionTypes.CheckSessionSuccess;
  constructor(public payload: string) {}
}

export class CheckSessionFailure implements Action {
  readonly type = LoginActionTypes.CheckSessionFailure;
  constructor(public payload: string) {}
}

export class SetUser implements Action {
  readonly type = LoginActionTypes.SetUser;
  constructor(public payload: UserModel) {}
}

export class LogOut {
  readonly type = LoginActionTypes.LogOut;
}

export class RedirectToMain implements Action {
  readonly type = LoginActionTypes.RedirectToMain;
}

export class RedirectToLogin implements Action {
  readonly type = LoginActionTypes.RedirectToLogin;
}

export type LoginAction =
  | SignIn
  | SignInSuccess
  | SignInFailure
  | CheckSession
  | CheckSessionSuccess
  | CheckSessionFailure
  | SetUser
  | LogOut
  | RedirectToMain
  | RedirectToLogin;
