import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoginState } from './core/login/+state/login.reducer';
import { CheckSession } from './core/login/+state/login.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private store: Store<LoginState>) { }
  title = ' Admin Panel';

  ngOnInit() {
    this.store.dispatch(new CheckSession());
  }
}
