import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Effect, ofType, Actions } from '@ngrx/effects';
import {
  switchMap,
  map,
  catchError,
  flatMap,
  withLatestFrom,
  filter,
  tap
} from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import {
  LoginActionTypes,
  SignIn,
  SignInSuccess,
  SignInFailure,
  RedirectToMain,
  SetUser,
  RedirectToLogin,
  CheckSessionFailure,
  CheckSessionSuccess
} from './login.actions';
import { LoginState } from './login.reducer';
import { AuthService } from '../../service/auth.service';
import { isUndefined } from 'util';

@Injectable()
export class LoginEffects {
  constructor(
    private actions: Actions,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<LoginState>,
    private auth: AuthService
  ) {}

  @Effect()
  signIn$ = this.actions.pipe(
    ofType(LoginActionTypes.SignIn),
    switchMap((a: SignIn) =>
      this.auth.login(a.payload).pipe(
        filter(d => !isUndefined(d)),
        map(r => new SignInSuccess(r)),
        catchError(error => of(new SignInFailure(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  signInSuccess$ = this.actions.pipe(
    ofType(LoginActionTypes.SignInSuccess),
    flatMap((a: SignInSuccess) => [
      new SetUser(a.payload),
      localStorage.setItem('loggedIn', 'true')
    ])
  );

  @Effect()
  checkSession$ = this.actions.pipe(
    ofType(LoginActionTypes.CheckSession),
    switchMap(() =>
      this.auth.checkSession().pipe(
        flatMap((result) => [new CheckSessionSuccess(result)]),
        catchError(error => of(new CheckSessionFailure(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  logOut$ = this.actions.pipe(
    ofType(LoginActionTypes.LogOut),
    switchMap(() =>
      this.auth.logout().pipe(
        tap(() => {
          localStorage.setItem('loggedIn', 'false');
          location.reload();
        }),
        catchError(error => {
          location.reload();
          return throwError(error);
        })
      )
    )
  );

  @Effect({ dispatch: false })
  redirectToMain$ = this.actions.pipe(
    ofType(LoginActionTypes.RedirectToMain),
    flatMap(async () => {
      const urlTreeQP = this.router.createUrlTree(['/'], {
        queryParams: this.activatedRoute.root.snapshot.queryParams
      });

      const urlQP = this.router.serializeUrl(urlTreeQP).substr(1);

      return await this.router.navigateByUrl(
        // this.activatedRoute.root.firstChild.snapshot.params.return ||
          '/' + urlQP,
        {
          replaceUrl: true
        }
      );
    })
  );

  // @Effect({ dispatch: false })
  // redirectToLogin$ = this.actions.pipe(
  //   ofType(LoginActionTypes.RedirectToLogin),
  //   flatMap(async () => {
  //     const urlTreeQP = this.router.createUrlTree(['/login'], {
  //       queryParams: this.activatedRoute.root.snapshot.queryParams
  //     });

  //     const urlQP = this.router.serializeUrl(urlTreeQP).substr(1);

  //     return await this.router.navigateByUrl(
  //       this.activatedRoute.root.firstChild.snapshot.params.return ||
  //         '/login' + urlQP,
  //       {
  //         replaceUrl: true
  //       }
  //     );
  //   })
  // );
}
