import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';

import { loginReducers, initLoginState } from './core/login/+state/login.reducer';
import { LoginEffects } from './core/login/+state/login.effects';
import { ApiService } from './core/api/api.service';
import { UploadApiService } from './core/api/upload-api-service';
import { LoaderComponent } from './main/loader/loader.component';
import { LoaderService } from './core/service/loader.service';
import { LoaderInterceptor } from './core/service/loader.interceptor';
import { MatProgressSpinnerModule, MatDialogModule, MatSidenavModule, MatDividerModule } from '@angular/material';
import {MatCardModule} from '@angular/material/card';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import { GridModule } from '@progress/kendo-angular-grid';
import { DialogsModule, WindowModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { IntlModule } from '@progress/kendo-angular-intl';

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IntlModule,
    WindowModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    DateInputsModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatIconModule,
    MatSelectModule,
    MatToolbarModule,
    MatDialogModule,
    MatSidenavModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(loginReducers, {
      initialState: initLoginState
    }),
    EffectsModule.forRoot([LoginEffects]),
    GridModule,
    DialogsModule,
    DropDownsModule
  ],
  providers: [
    ApiService,
    UploadApiService,
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
