import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, merge, throwError } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class UploadApiService {
  protected url = "/api/upload";
  fileUpload: any;
  files: any;

  constructor(protected http: HttpClient) {}

  /**
   *
   * @param files File төрөлтэй зураг байна.
   */
  upload(files: File | File[] | FileList): Observable<any | string[]> {
    if (files instanceof File) {
      return this.uploadFile(files);
    } else {
      const obs: Observable<string>[] = [];
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        obs.push(this.uploadFile(file));
        return merge(...obs);
      }
    }
  }

  private uploadFile(file: File): Observable<any> {
    if (file) {
      const formData: FormData = new FormData();
      formData.append("uploadFile", file, file.name);
      const headers = new HttpHeaders();
      headers.append("Accept", "application/json");
      // tslint:disable-next-line: object-literal-shorthand
      const options = { headers: headers };
      return this.http.post(this.url, formData, options);
    }
    return throwError("Файл алдаатай байна");
  }
}
